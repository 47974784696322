/*****************************************************************************
 * UmanIT - form utilities
 *****************************************************************************/

const buttons = document.querySelectorAll("[data-role='password-button']");

buttons.forEach( (button) => {

  let input = button.previousElementSibling;

  button.addEventListener("mousedown", function() {
    input.setAttribute('type', 'text');
  });

  button.addEventListener("mouseup", function() {
    input.setAttribute('type', 'password');
  });

  button.addEventListener("touchstart", function() {
    input.setAttribute('type', 'text');
  });

  button.addEventListener("touchend", function() {
    input.setAttribute('type', 'password');
  });

});
